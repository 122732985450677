import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import "./Jumbotron.less"

const Jumbotron = () => {
  const data = useStaticQuery(graphql`
    query mediaJumbotronQuery {
      strapiMediapage {
        MediaPageHeader
        SmallHeader
        Description
      }
    }
  `)

  return (
    <div className="media-jumbotron-container">
      <Container>
        <Row>
          <Col md={7}>
            <h1 className="display-1">{data?.strapiMediapage?.MediaPageHeader}</h1>
            <p className="h5">{data?.strapiMediapage?.SmallHeader}</p>
            <p className="h6">{data?.strapiMediapage?.Description}</p>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Jumbotron
