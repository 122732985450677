import React from "react"
import Cards from "../components/media/Cards"
import Jumbotron from "../components/media/Jumbotron"
import NavigationBar from "../components/navbar/navigationBar"
import Footer from "../components/footer/footer"
import Contact from "../components/media/Contact"
import SEO from "../components/head/seo"
import "./mediapage.less"

const mediapage = () => {
  return (
    <div className="media-page-container">
      <SEO title="Petrachor Media Inquiries – As Seen On" description="Petrachor has recently been featured on a number of podcasts, shows, and Youtube videos. Wanted to chat with our founder as well? Get in touch today."/>
      <NavigationBar />
      <Jumbotron />
      <Cards />
      <Contact />
      <Footer />
    </div>
  )
}

export default mediapage
