import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import "./Contact.less"

const Contact = () => {
  const data = useStaticQuery(graphql`
    query MediaContactQuery {
      strapiMediapage {
        ContactHeader
        ContactDescription
        CtaButtonText
      }
    }
  `)
  return (
    <div className="youtube-contact-form">
      <Container>
        <Row>
          <Col>
            <h2>{data?.strapiMediapage?.ContactHeader}</h2>
            <p className="h6">{data?.strapiMediapage?.ContactDescription}</p>
            <a href="mailto:todd@petrachor.com" className="btn mail-to-todd">{data?.strapiMediapage?.CtaButtonText}</a>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Contact
