import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Col, Container, Image, Row } from "react-bootstrap"
import "./Cards.less"

const Cards = () => {
  const data = useStaticQuery(graphql`
    query MediaCardsQuery {
      strapiMediapage {
        MediaPageCards {
          Description
          BackgroundColor
          Link
          Image {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  `)

  return (
    <Container className="media-cards-container">
      <Row>
        {data?.strapiMediapage?.MediaPageCards.map(item => {
          const bgColor = item?.BackgroundColor ? item.BackgroundColor : "#1F262C"
          return (
            <Col md={3}>
              <div className="media-card" style={{ backgroundColor: bgColor }}>
                <Image src={item?.Image.localFile.publicURL} />
                <div className="h6">{item?.Description}</div>
                <a href={item.Link} className="btn media-link">Watch Now</a>
              </div>
            </Col>
          )
        })}
      </Row>
    </Container>
  )
}

export default Cards
